
import React from "react"
import { api_freedom } from '../services/apis'
import jwt_decode from 'jwt-decode'
import AdicionarPessoa from "./AdicionarPessoa"
import moment from "moment"
import AlterarPessoa from "./AlterarPessoa"
class Pessoas extends React.Component {

    constructor(props) {
        super(props)
        if (!localStorage.getItem('autenticado_freedom')) {
            props.history.push('/')
        }

        this.state = {
            modalAdicionarPessoa: "",
            modalAlterarPessoa: "",
            pessoas: [],
            por_pagina: 10,
            pagina: 1,
            mensagem: 'Carregando ....',
            total_pessoas: 0,
            buscar_cpf_cnpj: '',
            id: '',
            nome: '',
            cpf_cnpj: '',
            rg_ie: '',
            dados_login: jwt_decode(localStorage.getItem('autenticado_freedom')),
            email: '',
            dataNasc: '',
            telefone: '',
            celular: '',
            endereco: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            uf: '',
            cep: '',
            modal_titulo: '',
            modal_corpo: '',
            modal_botoes: ''
        }

        this.quantidade_paginas = 1
    }

    componentDidMount() {
        this.pessoas()
    }

    // async componentDidUpdate(prevProps, prevState) {

    //     if (prevState.pagina !== this.state.pagina || prevState.por_pagina !== this.state.por_pagina) {
    //         await this.pessoas();
    //     }

    // }

    pessoas = async () => {
        try {
            let pessoas = await api_freedom.get(`/pessoa/imobiliaria/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
            if (pessoas.data.data.indexOf('TOKEN') === -1) {
                this.setState({ pessoas: pessoas.data.data, total_pessoas: pessoas.data.data.length })
                this.quantidade_paginas = Math.ceil(pessoas.data.data.length / 10)
            }
        }
        catch (error) {
            console.log(error.message)
        }
    }

    listarPessoas = (record, index) => {
        console.log(record)
        return (
            <tr key={index}>
                <td>{record.id}</td>
                <td>{record.nome} </td>
                <td>{record.cpf_cnpj}</td>
                <td>{record.email}</td>
                <td>{record.celular}</td>
                <td>{moment(record.data_nasc).format('DD/MM/YYYY') !== "Invalid date" ? moment(record.data_nasc).format('DD/MM/YYYY') : ''}</td>
                <td><button hidden={this.disable()} className='btn__primary--outline button'
                    onClick={() => {
                        // this.alterarPessoa(record) 
                        this.setState({
                            modalAlterarPessoa: <AlterarPessoa
                                pessoa={record}
                                fechar={this.fecharModal}
                                atualizar={this.pessoas}
                            />
                        })
                    }}
                >Alterar</button>
                </td>
            </tr>
        )
    }

    disable() {
        if (jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "Read") {
            return true
        } else {
            return false
        }
    }

    genCSV = () => {
        let csv = '"ID Pessoa","Nome","CPF/CNPJ","Email","Contato","Data de nascimento"\n';

        for (const i of this.state.pessoas) {
            csv += `"${i.id}","${i.nome}", "${i.cpf_cnpj}", "${i.email}", "${i.celular}", ${moment(i.data_nasc).format('DD/MM/YYYY') !== "Invalid date" ? moment(i.data_nasc).format('DD/MM/YYYY') : ''},`

            csv += '\n'
        }

        csv = csv.slice(0, csv.length - 1)

        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = `${this.state.dados_login.imobiliaria}_pessoas.csv`;
        hiddenElement.click();
    }


    abrirModal = () => {
        var modal = document.getElementById('modal');
        modal.style.display = "flex";
    }

    fecharModal = () => {
        this.setState({ modalAdicionarPessoa: "" })
        this.setState({ modalAlterarPessoa: "" })
        var modal = document.getElementById('modal');
        modal.style.display = "none";
    }

    filtrar = (pessoas) => {
        var filtro = pessoas.filter((p) => {
            return this.filtrarCPF_CNPJ(p)
        })

        this.quantidade_paginas = Math.ceil(filtro.length / 10)

        return filtro
    }

    filtrarCPF_CNPJ = (pessoa) => {
        return !this.state.buscar_cpf_cnpj || (pessoa.cpf_cnpj && pessoa.cpf_cnpj.toString().toLowerCase().includes(this.state.buscar_cpf_cnpj))
    }

    disable_button() {
        if (jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "Read") {
            return true
        } else {
            return false
        }
    }

    render() {
        return (
            <>
                <div>
                    {this.state.pessoas.length > 0 &&
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <label>Total de pessoas: {this.state.total_pessoas}</label>
                        </div>
                    }
                    <div className="d-flex justify-content-between">
                        <div style={{ bottom: 0, marginBottom: 0, paddingBottom: 0, marginTop: 30 }}>
                            <button
                            hidden={this.disable()}
                                className="button btn__primary"
                                onClick={() => {
                                    this.setState({
                                        modalAdicionarPessoa: <AdicionarPessoa
                                            fechar={this.fecharModal}
                                            atualizar={this.pessoas}
                                        />
                                    })
                                }}
                            >
                                Pessoa{" "}
                                <i style={{ fontSize: "0.9em" }} className="fas fa-plus" />
                            </button>
                            <button class="button btn__primary" hidden={this.disable()} onClick={this.genCSV}>
                                    Gerar CSV
                                </button>

                        </div>
                        <div className="grupo__campo" style={{ width: "200px" }}>
                            <label>CPF/CNPJ:</label>
                            <div className="grupo__campo__form grupo__campo--input--select">
                                <input type='text' value={this.state.buscar_cpf_cnpj} onChange={(event) => {
                                    this.setState({ buscar_cpf_cnpj: event.target.value, pagina: 1 })
                                }} />
                            </div>
                        </div>

                        {/* <hr /> */}
                    </div>
                    {/* <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <label style={{ margin: '0px 3px 3px 0px' }}>Por página aqui</label>
                        <select className="ml-2"
                            value={this.state.por_pagina}
                            onChange={(i) => {
                                this.setState({ por_pagina: i.target.value, pagina: 1 })
                            }}
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="1000">1000</option>
                        </select>
                    </div> */}

                    <div className='table mt-2'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>
                                        <div class="thead__item">
                                            <label>Pessoa</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Nome</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>CPF/CNPJ</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Email</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Contato</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Data de Nascimento</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item" hidden={this.disable()}>
                                            <label>Alterar</label>
                                        </div>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {this.filtrar(this.state.pessoas).length === 0 && <tr><th className='text-center' colSpan='8'>{this.state.mensagem}</th></tr>}
                                {this.filtrar(this.state.pessoas).slice((this.state.pagina - 1) * 10, this.state.pagina * 10).map(this.listarPessoas)}
                            </tbody>
                        </table>
                    </div>
                    {this.filtrar(this.state.pessoas).length > 0 && <div className='pagination'>
                        <div className='pagination__arrow' onClick={() => {
                            this.setState({ pagina: this.state.pagina > 1 ? this.state.pagina - 1 : 1 })
                        }}>
                            {'<'}
                        </div>
                        <div className="pagination__numbers">
                            {
                                [...Array(this.quantidade_paginas)].map((pagina, index) => {
                                    if ((this.state.pagina === 1 && index + 1 < 4) ||
                                        (this.state.pagina === this.quantidade_paginas && index + 1 > this.quantidade_paginas - 3) ||
                                        (index + 1 === this.state.pagina - 1 || index + 1 === this.state.pagina || index + 1 === this.state.pagina + 1)
                                    ) {
                                        return (
                                            <p className={this.state.pagina === index + 1 ? 'pagination__index' : ''} onClick={() => {
                                                this.setState({ pagina: index + 1 })
                                            }}>
                                                {index + 1}
                                            </p>
                                        )
                                    }
                                    else {
                                        return null
                                    }
                                })
                            }
                        </div>
                        <div className="pagination__arrow" onClick={() => {
                            this.setState({ pagina: this.state.pagina < this.quantidade_paginas ? this.state.pagina + 1 : this.quantidade_paginas })
                        }}>
                            {'>'}
                        </div>
                    </div>}
                </div>
                <div className="content-modal" id="modal" style={{ display: 'none' }}>
                    <div className="modal">
                        <div className="modal-head">
                            {this.state.modal_titulo}
                        </div>
                        <div className="modal-body">
                            <div className='modal-scroll'>
                                {this.state.modal_corpo}
                            </div>
                        </div>
                        <div className="modal-foot" >
                            {this.state.modal_botoes}
                        </div>
                    </div>
                </div>
                {this.state.modalAdicionarPessoa && this.state.modalAdicionarPessoa}
                {this.state.modalAlterarPessoa && this.state.modalAlterarPessoa}
            </>
        );
    }
}

export default Pessoas;
