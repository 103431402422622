import React from "react"
import {api_freedom} from '../services/apis'
import jwt_decode from 'jwt-decode'
import jwt from 'jsonwebtoken'
import moment from "moment"
import ModalErrosDimob from './ModalErrosDimob';
import Swal from 'sweetalert2'
import ModalEditarDimob from './ModalEditarDimob';
import ModalEnviarDimobs from "./ModalEnviarDimobs"

class IndicesPage extends React.Component {
    constructor(props){
        super(props)

        if(!localStorage.getItem('autenticado_freedom')){
            props.history.push('/')
        } 

        // if (jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo !== "ReadWriteMaster"){
        //     window.location.href = "/freedom/contratos"
        // }

        this.state = {
            dados_login: jwt_decode(localStorage.getItem('autenticado_freedom')),
            premissao_tipo: jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "ReadWriteMaster" || "ReadWrite" ? true : false,
            premissao_tipo_master: jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "ReadWriteMaster" ? true : false,
            dimobs: [],
            anosDimob: [],
            ano: "0",
            declarante: [],
            downloadDimobs: [],
            errosDimob: [],
            modalErrosDimob: "",
            modalEditarDimob: "",
            modalEnviarDimobs: "",
            mensagem: "Carregando ....",
            loadingDimobs: true,
            loading: false,
            dimobsSelecionados: [],
            selecionaTudo: false
        }
        this.getDimobs()
    }

    async componentDidUpdate(prevProps,prevState){
        
        // if (prevState.) {
        //     await this.contratos();
        // }

    }

    disabled() {
        if (jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "Read") {
            return true
        } else {
            return false
        }
    }

    abrirModal = (id) => {
        var modal = document.getElementById(id);
        modal.style.display = "flex";
    }

    fecharModal = (id) => {
        this.setState({ modalErrosDimob: "" })
        var modal = document.getElementById(id);
        modal.style.display = "none";
    }

    fecharModalDimob = (id) => {
        this.setState({ modalErrosDimob: "" })
        this.setState({ modalEditarDimob: "" })
    }

    dimobToPDF = async (linkDemonstrativo, item) => {

        Swal.fire({
            title: 'Processando...',
        })
        Swal.showLoading()


        let {data} = await api_freedom.get(`demonstrativo/demonstrativodimob/pdf/${linkDemonstrativo}`)

        var hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/html'+ ";base64," + encodeURIComponent(data.data);
            hiddenElement.target = '_blank';
            hiddenElement.download = `DemonstrativoDimob_ID_${item.id}_Contrato_${item.contrato_id}_Ano_${this.state.ano}.pdf`;
            hiddenElement.click();

        Swal.close()

    }

    getDimobs = async () => {

        this.setState({ 
            loadingDimobs : true,
            mensagem: "Carregando ...."
        });

        let dataDimob = await api_freedom.get(`/financeiro/anosdimob/${this.state.dados_login.imobiliaria_id ? this.state.dados_login.imobiliaria_id : 53}`, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
        if(dataDimob.data.data.indexOf('TOKEN') === -1){
            this.setState({
                anosDimob: dataDimob.data.data,
            })
        }

        let {data} = await api_freedom.get(`/financeiro/dimob/${this.state.dados_login.imobiliaria_id ? this.state.dados_login.imobiliaria_id : 53}/${this.state.ano}`, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})

        if(data.data.indexOf('TOKEN') === -1){
            this.setState({
                dimobs: data.data,
            })

            if(this.state.dimobs.length === 0 && this.state.ano !== "0"){
                this.setState({ mensagem: "Nenhum registro encontrado."})      
            } 
            else{
                this.setState({ mensagem: "Selecione um ano"})
            }
        }

        this.setState({ loadingDimobs : false });
    }

    downloadDimobs = async () => {
        this.setState({ 
            loading : true,
        });
        let {data} = await api_freedom.get(`/demonstrativo/declaracao/dimob/${this.state.dados_login.imobiliaria_id ? this.state.dados_login.imobiliaria_id : 53}/${this.state.ano}`, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})

        console.log(data.data[0].dados);

        if(data.data.indexOf('TOKEN') === -1){
            this.setState({
                errosDimob: data.data[0].errosPreenchimento,
                declarante: data.data[0].declarante,
                downloadDimobs: data.data[0].dados,
            })

            if(this.state.errosDimob.length > 0){

                this.setState({
                    modalErrosDimob: <ModalErrosDimob 
                    erros={this.state.errosDimob}
                    fechar={this.fecharModalDimob}
                    atualizar={this.getDimobs}
                  />
                  })

            } else if(data.data[0].dados.length === 0){
                this.setState({ loading : false });
                return null
            }
            else{

                let txt = "DIMOB                                                                                                                                                                                                                                                                                                                                                                                 \n";
    
                txt += "R01";
                txt += this.state.declarante.cnpj;
                txt += this.state.downloadDimobs[0].ano_calendario;
                txt += this.state.declarante.delcaracao_retificadora;
                txt += this.state.declarante.numero_recibo;
                txt += this.state.declarante.situacao_especial;
                txt += moment().format('DDMMYYYY');
                txt += this.state.declarante.codigo_situacao_especial;
                txt += this.state.declarante.razao_social;
                txt += this.state.declarante.cpf_responsavel_rfb;
                txt += this.state.declarante.endereco;
                txt += this.state.declarante.estado;
                txt += this.state.declarante.codigo_municipio_contribuinte;
                txt += "                    ";
                txt += "          ";
                txt += "\n";
    
                this.state.downloadDimobs.map((item) => {
                    txt += "R02"
                    txt += item.cnpj_declarante
                    txt += item.ano_calendario
                    txt += item.sequencial_locacao
                    txt += item.cpf_cnpj_locador
                    txt += item.nome_locador
                    txt += item.cpf_cnpj_locatario
                    txt += item.nome_locatario
                    txt += item.numero_contrato
                    txt += moment(item.data_contrato).format("DDMMYYYY")
                    item.dimob.map((dimob) => {
                        txt += dimob.valor_aluguel
                        txt += dimob.valor_comissao
                        txt += dimob.imposto_retido
                    })
                    txt += item.tipo_imovel
                    txt += item.endereco_imovel
                    txt += item.cep
                    txt += item.codigo_municipio_imovel
                    txt += "                    "
                    txt += item.uf
                    txt += "          "
                    txt += "\n"
                })
    
                txt += "T9                                                                                                    ";
                txt += "\n";
    
                var hiddenElement = document.createElement('a');
                hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(txt);
                hiddenElement.target = '_blank';
                hiddenElement.download = 'dimob.txt';
                hiddenElement.click();

            }
        }
        this.setState({ loading : false });
            
    }

    selecionarTudo = (e) => {
        
        let selecionados = []
        
        if(!this.state.selecionaTudo) {
            for(const dimob of this.state.dimobs) {
                selecionados.push(dimob)
            }
        }
        this.setState({
            dimobsSelecionados: selecionados,
            selecionaTudo: e.target.checked
        })
    }
    
    checkDimob = (dimob) => {


        let selecionados = this.state.dimobsSelecionados

        if(selecionados.find(element => element.id === dimob.id)) {
            selecionados = selecionados.filter(element => element.id !== dimob.id)
            if(this.state.selecionaTudo) {
                this.setState({
                    selecionaTudo: false,
                })
            }
        } else {
            selecionados.push(dimob)
        }
        
        this.setState({
            dimobsSelecionados: selecionados,
        })
    }

    render(){
        return (
            <>
                <div>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: "end"}}>
                        <div>
                            {this.state.premissao_tipo && this.state.dimobs.length > 0 ? 
                                <button
                                // class="button btn__primary mx-2"
                                className="button btn__primary mx-2"
                                onClick={() => this.downloadDimobs()}
                                >
                                {this.state.loading ? 
                                    <div class="spinner-border text-light" role="status"></div>
                                : 
                                    <i style={{ fontSize: "0.9em" }} className="fas fa-download" />
                                }
                                {" "}Dimob
                                </button>
                            : null
                            }
                            {this.state.premissao_tipo_master ? 
                                <button
                                // class="button btn__primary mx-2"
                                className="button btn__primary mx-2"
                                onClick={() => this.props.history.push('municipiosdimob')}
                                >
                                {/* <i style={{ fontSize: "0.9em" }} className="fas fa-download" /> */}
                                {" "}Municípios Dimob
                                </button>
                            : null
                            }
                            {this.state.premissao_tipo_master && this.state.dimobsSelecionados.length > 0 ? 
                                <button
                                // class="button btn__primary mx-2"
                                className="button btn__primary mx-2"
                                onClick={() => this.setState({
                                    modalEnviarDimobs: <ModalEnviarDimobs
                                        fechar={() => this.setState({ modalEnviarDimobs: '' })}
                                        dados={this.state.dimobsSelecionados}
                                    />
                                })}
                                >
                                {/* <i style={{ fontSize: "0.9em" }} className="fas fa-download" /> */}
                                {" "}Enviar Dimobs
                                </button>
                            : null
                            }
                        </div>
                        <div>
                                <div className="grupo__campo" name={`ano`}>
                                <label style={{ width: "100%" }}>Ano: </label>
                                <div className="grupo__campo__form">
                                    <select
                                    value={this.state.ano}
                                    onChange={(event) => {
                                        this.setState({ ano: event.target.value });
                                        this.getDimobs()
                                    }}
                                    required
                                    >
                                    <option value="0">Selecione</option>
                                    {this.state.anosDimob.map(item => (
                                        <option value={item.ano}>{item.ano}</option>
                                    ))}
                                    </select>
                                </div>
                                </div>
                            </div>
                    </div>
                    <div className='table mt-4'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>
                                        <div className="mb-4 ml-2" style={{ display: 'flex'}}>
                                            <div>
                                                <label className="mb-0 checkbox-label">
                                                    <input
                                                        disabled={this.state.dimobs.length > 0 ? false : true}
                                                        type="checkbox"
                                                        name="check"
                                                        checked={this.state.selecionaTudo}
                                                        onChange={(e)=> this.selecionarTudo(e)}
                                                    />
                                                    <span class="checkbox-custom rectangular"></span>
                                                </label>
                                            </div>
                                            <div className="w-100">
                                                <label>Selecionar Tudo</label>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>ID</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Contrato</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Locador</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Visualizar / PDF</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item" hidden={this.disabled()}>
                                            <label>Editar</label>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.loadingDimobs || this.state.dimobs.length === 0 ? (
                                        <tr><th className='text-center' colSpan='8'>{this.state.mensagem}</th></tr>
                                    ) : (
                                    this.state.dimobs.map(e => (
                                        <tr>
                                            <td className="tbody__text">
                                                <div className="mt-auto mb-3">
                                                    <label class="mb-0 checkbox-label">
                                                        <input
                                                            type="checkbox"
                                                            name="check"
                                                            checked={this.state.dimobsSelecionados.find(element => element.id === e.id)}
                                                            onChange={() => this.checkDimob(e)}
                                                        />
                                                        <span class="checkbox-custom rectangular"></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td className="tbody__text"><p>{e.id}</p></td>
                                            <td className="tbody__text"><p>{e.contrato_id}</p></td>
                                            <td className="tbody__text"><p>{e.nome}</p></td>
                                            <td className="tbody__text justify-content-between">
                                                <button className='btn__freedom btn--normal' style={{marginTop: '-1px', width: '30px', height: '23px', marginLeft: '20px'}}
                                                    onClick={() => {
                                                        var linkDemonstrativo = jwt.sign({ 
                                                            ano: this.state.ano,
                                                            contrato_id: e.contrato_id,
                                                            pessoa_id: e.pessoa_id,
                                                            imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id
                                                        }, 'DIMOB')
                                                        window.open(`/freedom/demonstrativodimob/${linkDemonstrativo}`, '_blank');
                                                    }} 
                                                    ><i className='fas fa-eye'/>
                                                </button>
                                                <button className='btn__freedom btn--normal' style={{marginTop: '-1px', width: '30px', height: '23px', marginLeft: '20px'}}
                                                    onClick={() => {
                                                        const linkDemonstrativo = jwt.sign({ 
                                                            ano: this.state.ano,
                                                            contrato_id: e.contrato_id,
                                                            pessoa_id: e.pessoa_id,
                                                            imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id
                                                        }, 'DIMOB')
                                                        this.dimobToPDF(linkDemonstrativo, e);
                                                    }} 
                                                    >
                                                        <i class="fas fa-file-pdf" />
                                                </button>
                                            </td>
                                            <td className="tbody__text">
                                                <button
                                                hidden={this.disabled()}
                                                    className="button btn__primary--outline"
                                                    onClick={() => {
                                                        this.setState({
                                                            modalEditarDimob: <ModalEditarDimob 
                                                            data={{ano: this.state.ano,
                                                                contrato_id: e.contrato_id,
                                                                pessoa_id: e.pessoa_id,
                                                                imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
                                                                locador: e.nome
                                                            }}
                                                            fechar={this.fecharModalDimob}
                                                            atualizar={this.getDimobs}
                                                            />
                                                        })
                                                    }}
                                                    >
                                                    <i className='fas fa-pen'/>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {this.state.modalErrosDimob && this.state.modalErrosDimob}
                {this.state.modalEditarDimob && this.state.modalEditarDimob}
                {this.state.modalEnviarDimobs && this.state.modalEnviarDimobs}
        
            </>
        )
    }
}

export default IndicesPage