import React from "react"
import { api_freedom } from '../services/apis'
import moment from 'moment'
import jwt from 'jsonwebtoken'
import jwt_decode from 'jwt-decode'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import ModalErroGerarBoleto from './ModalErroGerarBoleto';
import calcularImpostoLocador from '../util/calcularImpostoLocador';

class Parcelas extends React.Component {

    constructor(props) {
        super(props)
        if (!localStorage.getItem('autenticado_freedom')) {
            props.history.push('/')
        }

        this.state = {
            contrato: {},
            parcelas: [],
            pagina: 1,
            quantidade_paginas: 1,
            mensagem: 'Carregando ....',
            total_parcelas: 0,
            acao: '',
            servicos: [],
            servico: '',
            valor_servico: 0,
            vencimento_servico: '',
            referencia_servico: '',
            mes_ano_servico: '',
            selectedOption: 'false',
            selectedYear: new Date().getFullYear(),
            modal_titulo: '',
            modal_corpo: '',
            modal_botoes: '',
            valor_aluguel: 0,
            dataVencimento: '',
            modalErroGerarBoleto: '',

            value: [],

            addMesAno: '',
            addMesAnoInicial: '',
            addMesAnoFinal: '',
            load: false,

            manual_valor_pago: 0,
            manual_data_pago: 0,
            manual_document_number: 0,
            manual_data_vencimento: '',
            manul_id_boleto: -1,

            locadores: [],
            locador: {},
            despesas_dedutiveis: 0,
            locadorSelecionado: null,
            vencimento: '',
            mes_ano: '',
            valorAluguelIR: 0,
            multisplit: false,

            descricao_pagamento: '',
            valor_multisplit: '',
            banco: '',
            agencia: '',
            conta: '',
            corrente_poupanca: 0,
            beneficiario_cpf_cnpj: '',
            beneficiario_nome: ''
        }

    }

    componentDidMount() {
        this.contrato()
        this.parcelas()
        this.servicos()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedOption !== this.state.selectedOption || prevState.selectedYear !== this.state.selectedYear) {
            this.parcelas();
            this.servicos();
            this.render();
        }
    }
    contrato = async () => {
        try {
            let contrato = await api_freedom.get(`/contrato/detalhe/${this.props.match.params.id}/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
            if (contrato.data.data.indexOf('TOKEN') === -1) {

                this.setState({ multisplit: contrato.data.data[0].multisplit })

                this.setState({ contrato: contrato.data.data[0] })
                const locadores = contrato.data.data[0].pessoas.filter((p) => {
                    return p.tipo === 'Locador'
                });
                locadores.forEach((locador) => {
                    const resultadoIR = calcularImpostoLocador(locador.percentagem, contrato.data.data[0].valor_aluguel, 0, locador.nome + "; CPF: " + locador.cpf_cnpj);
                    locador.resultadoIR = resultadoIR.resultadoIR;
                    locador.aliquotaIR = resultadoIR.aliquotaIR;
                    locador.valorPagarIR = resultadoIR.valorPagarIR;
                    locador.valorAluguelIR = resultadoIR.valorAluguelIR;
                });

                this.setState({
                    locadores: contrato.data.data[0].pessoas.filter((p) => {
                        return p.tipo === 'Locador'
                    }),
                    valorAluguelIR: contrato.data.data[0].valor_aluguel,
                    locadorSelecionado: locadores.length > 0 ? locadores[0] : null,
                })

                if (jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id !== contrato.data.data[0].imobiliaria_id) {
                    this.props.history.push('/freedom/contratos')
                }
            }
        }
        catch (error) {
            console.log(error.message)
        }
    }

    parcelas = async () => {
        try {
            let parcelas = await api_freedom.get(`/financeiro/idcontrato/${this.props.match.params.id}/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}?selectedYear=${this.state.selectedYear}&selectedPaid=${this.state.selectedOption}`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

            if (parcelas.data.data.indexOf('TOKEN') === -1) {
                var quantidade = parcelas.data.data.filter((p) => {
                    return p.nome === 'Aluguel'
                }).length

                this.setState({
                    parcelas: parcelas.data.data,
                    quantidade_paginas: Math.ceil(quantidade / 10),
                    mensagem: 'Nenhum registro encontrado.',
                    total_parcelas: quantidade
                })
            }
        }
        catch (error) {
            console.log(error.message)
        }
    }

    servicos = async () => {
        try {
            let servicos = await api_freedom.get(`/servico/all`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
            if (servicos.data.data.indexOf('TOKEN') === -1) {
                this.setState({
                    servicos: servicos.data.data,
                    servico: servicos.data.data.filter((s) => {
                        return s.automatico !== 1
                    })[0].id
                })
            }
        }
        catch (error) {
            console.log(error.message)
        }
    }

    baixaManual = async () => {
        if (!this.state.manual_data_pago || !this.state.manual_valor_pago) {
            alert("Preencha os campos")
            return
        }
        await api_freedom.post("/financeiro/baixamanual", {
            data_pago: this.state.manual_data_pago,
            valor_pago: this.state.manual_valor_pago,
            id: this.state.manul_id_boleto,
            document_number: this.state.manual_document_number,
            data_venc: this.state.manual_data_vencimento
        }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

        this.setState({
            manual_data_pago: '',
            manual_valor_pago: '',
        })

        this.contrato()
        this.parcelas()
        this.servicos()
        this.fecharModal()
    }

    openModalManual = async (id, document, data_venc) => {
        this.setState({
            manul_id_boleto: id,
            manual_document_number: document,
            manual_data_vencimento: data_venc,
            modal_titulo: `Baixa manual de boleto`,
            modal_corpo: <div>
                <div style={{ marginTop: '1%' }}>
                    <div className='grupo__campo'>
                        <label style={{ width: '100%' }}>Valor pago: </label><br />
                        <div className='grupo__campo__form'>
                            <input type='number' defaultValue={this.state.manual_valor_pago} onInput={a => this.setState({ manual_valor_pago: a.target.value })} />
                        </div>
                    </div>
                    <div className='grupo__campo'>
                        <label style={{ width: '100%' }}>Data pago: </label><br />
                        <div className='grupo__campo__form'>
                            <input type='date' defaultValue={this.state.manual_data_pago} onChange={a => this.setState({ manual_data_pago: a.target.value })} />
                        </div>
                    </div>
                </div>
            </div>,
            modal_botoes: <>
                <button className='button btn__primary--outline' onClick={() => { this.fecharModal() }}>Cancelar</button>
                <button className='button btn__primary' onClick={() => { this.fecharModal(); this.baixaManual(); }}>Salvar</button>
            </>
        })
        this.abrirModal()
    }

    reenviar_boleto = async (bid) => {
        await api_freedom.post('/financeiro/reenviarboleto', {
            id: bid
        }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

        alert("Email reenviado com sucesso ")
    }

    abrirModalIR = () => {
        const modal = document.getElementById('modalIR');
        modal.style.display = "flex";
    }

    fecharModalIR = () => {
        const modal = document.getElementById('modalIR');
        modal.style.display = "none";
    }

    abrirModalMultisplit = () => {
        const modal = document.getElementById('multisplit');
        modal.style.display = "flex";
    }

    fecharModalMultisplit = () => {
        const modal = document.getElementById('multisplit');
        modal.style.display = "none";
    }

    handleLocadorChange = (event) => {
        const locadorId = parseInt(event.target.value);
        const locadorSelecionado = this.state.locadores.find(
            (locador) => locador.id === locadorId
        );
        this.setState({ locadorSelecionado });
    };


    handleInputChange = (event) => {
        const { id, value } = event.target;
        const locadorSelecionado = {
            ...this.state.locadorSelecionado,
            [id]: value,
        };
        this.setState({ locadorSelecionado });
        const locadores = this.state.locadores.map((locador) => {
            if (locador.id === locadorSelecionado.id) {
                return locadorSelecionado;
            }
            return locador;
        });
        this.setState({ locadores });
    };

    listarParcelas = (record, index) => {
        //   console.log(record);
        var valor_boleto = 0
        this.state.parcelas.filter((p) => {
            return p.gerar_boleto === 1 && p.mes_ano === record.mes_ano
        })
        // .map((f) => {
        //     if(f.credito_debito === 1){
        //         valor_boleto += f.valor
        //     } 
        //     else{
        //         valor_boleto -= f.valor
        //     }
        //     return null
        // })

        // console.log(record)

        var class_name = {};
        var button_gerar_boleto = {}
        if (record.ativo === 0) {
            class_name = { "textDecoration": "line-through" }
            button_gerar_boleto = { display: 'none' }
        } else {
            button_gerar_boleto = { marginTop: '-1px', width: '100px', height: '23px' }
        }

        let transf = this.state.parcelas.find(e => e.mes_ano == record.mes_ano && e.servico_id == 2)

        let parLoc = this.state.parcelas.filter((parcela) => { return record.mes_ano === parcela.mes_ano && parcela.servico_id == 2 })

        if (parLoc.length > 0) {
            var parcelasLocador = parLoc
        }
        if (this.state.selectedOption === 'true' && record.pago != null) {
            return (
                <tr style={class_name} key={index}>
                    <td>{record.id}</td>
                    <td>{this.capitalize(new Date(record.mes_ano).toLocaleString('pt-BR', { month: 'long' }))} </td>
                    <td>{moment(record.data_vencimento).utc(false).format('DD/MM/YYYY')}</td>
                    <td>{record.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                    <td>
                        {/* {record.boleto_valor ? record.boleto_valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : valor_boleto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} */}
                        <button className='ml-3' style={{ border: '1px solid #6b24d6', borderRadius: '2px', padding: '0px 10px', backgroundColor: '#6b24d6', color: 'white' }}
                            onClick={() => { this.props.history.push(`/freedom/detalheparcela/${this.props.match.params.id}/${record.id}`) }}> ? </button>
                    </td>
                    <td>
                        {record.boleto_vencimento ?
                            <div>
                                {moment(record.boleto_vencimento).utc(false).format('DD/MM/YYYY')}
                                {!record.pago && <button className='btn__freedom btn--normal' style={{ marginTop: '-1px', width: '30px', height: '23px', marginLeft: '5px' }}
                                    onClick={() => {
                                        window.open(record.url, '_blank')
                                    }}
                                ><i className='fas fa-eye' /></button>}
                            </div> : //'Não gerado'
                            <button hidden={this.disable_button()} className='btn__primary--noborder' style={button_gerar_boleto} onClick={() => { this.gerarBoleto(record) }}> Gerar boleto </button>
                        }
                    </td>
                    <td>
                        {record.pago ? `Sim, dia ${moment(record.pago).utc(false).format('DD/MM/YYYY')}` : `Não`}
                        {record.baixa_manual == 1 ? " (manualmente)" : ''}
                    </td>
                    <td>
                        {transf && transf.payment_date ? `Sim, dia ${moment(transf.payment_date).utc(false).format('DD/MM/YYYY')}` : `Não`}
                        {record.repasse_garantido == 'RG' ? "(repasse garantido)" : ''}
                    </td>
                    <td>
                        <select onChange={(event) => {
                            this.setState({ acao: event.target.value })
                            if (event.target.value === 'Ajustes') this.ajustesParcela(record.id)
                            if (event.target.value === 'Multisplit') {
                                this.setState({ servico: 49 })
                                const id = parseInt(record.id);

                                const vencimento = moment(this.state.parcelas.filter((p) => {
                                    return p.id === id
                                })[0].data_vencimento).utc(false).format('YYYY-MM-DD')

                                const mes_ano = this.state.parcelas.filter((p) => {
                                    return p.id === id
                                })[0].mes_ano

                                this.setState({
                                    vencimento: vencimento,
                                    mes_ano: mes_ano,
                                })
                                this.abrirModalMultisplit()
                            }
                            if (event.target.value === 'Retenção IR') {
                                this.setState({ servico: 6 })
                                const id = parseInt(record.id);

                                const vencimento = moment(this.state.parcelas.filter((p) => {
                                    return p.id === id
                                })[0].data_vencimento).utc(false).format('YYYY-MM-DD')

                                const mes_ano = this.state.parcelas.filter((p) => {
                                    return p.id === id
                                })[0].mes_ano

                                this.setState({
                                    vencimento: vencimento,
                                    mes_ano: mes_ano,
                                })
                                this.abrirModalIR()
                            }
                            if (event.target.value === 'Demonstrativo do pagamento') {
                                var link = jwt.sign({
                                    contrato_id: this.props.match.params.id,
                                    financeiro_id: record.id,
                                    imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id
                                }, 'BOLETO')
                                window.open(`/freedom/demonstrativopagamento/${link}`)
                            }
                            if (event.target.value.split(',')[0] === 'locador') {
                                var linkDemonstrativo = jwt.sign({
                                    contrato_id: this.props.match.params.id,
                                    financeiro_id: record.id,
                                    pessoa_id: parseInt(event.target.value.split(',')[1]),
                                    imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id
                                }, 'BOLETO')
                                window.open(`/freedom/demonstrativolocador/${linkDemonstrativo}`)
                            }
                            if (event.target.value === 'Alterar parcela') this.props.history.push(`/freedom/alterarparcela/${this.props.match.params.id}/${record.id}`)
                            if (event.target.value === 'Alterar boleto') this.props.history.push(`/freedom/alterarboleto/${this.props.match.params.id}/${record.id}`)
                            if (event.target.value === 'Deletar boleto') this.modalDelete(record.boleto_id)
                            if (event.target.value === "Deletar parcela") this.confirmDelete(record.id)
                            if (event.target.value === "Baixa manual") this.openModalManual(record.boleto_id, record.document_number, record.boleto_vencimento)
                            if (event.target.value === "Reenviar boleto") this.reenviar_boleto(record.boleto_id)
                            event.target.value = 'Ações'
                        }}>
                            <option>Ações</option>
                            {!record.pago && !record.boleto_vencimento && <option>Ajustes</option>}
                            {!record.pago && !record.boleto_vencimento && <option>Retenção IR</option>}
                            <option>Demonstrativo do pagamento</option>
                            {parcelasLocador && parcelasLocador.map((pL, indice) => {
                                return <option key={indice} value={`locador,${pL.pessoa_id}`}>Demonstrativo do locador {pL.pessoa_nome.split(' ')[0]}</option>
                            })}
                            {!record.boleto_vencimento && !record.pago && <option hidden={this.disable_button()}>Alterar parcela</option>}
                            {record.boleto_vencimento && !record.pago && <option hidden={this.disable_button()}>Alterar boleto</option>}
                            {record.boleto_vencimento && !record.pago && <option hidden={this.disable_button()}>Deletar boleto</option>}
                            {!record.boleto_vencimento && !record.pago && <option hidden={this.disable_button()}>Deletar parcela</option>}
                            {record.boleto_vencimento && !record.pago && <option hidden={this.disable_button()}>Baixa manual</option>}
                            {record.boleto_vencimento && !record.pago && <option hidden={this.disable_button()}>Reenviar boleto</option>}
                            {this.state.multisplit === 1 && !record.pago && <option hidden={this.disable_button()}>Multisplit</option>}
                        </select>
                    </td>
                </tr>
            )
        } else if (this.state.selectedOption === 'false' && record.pago == null) {
            return (
                <tr style={class_name} key={index}>
                    <td>{record.id}</td>
                    <td>{this.capitalize(new Date(record.mes_ano).toLocaleString('pt-BR', { month: 'long' }))} </td>
                    <td>{moment(record.data_vencimento).utc(false).format('DD/MM/YYYY')}</td>

                    <td>{record.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                    <td>
                        {/* {record.boleto_valor ? record.boleto_valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : valor_boleto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} */}
                        <button className='ml-3' style={{ border: '1px solid #6b24d6', borderRadius: '2px', padding: '0px 10px', backgroundColor: '#6b24d6', color: 'white' }}
                            onClick={() => { this.props.history.push(`/freedom/detalheparcela/${this.props.match.params.id}/${record.id}`) }}> ? </button>
                    </td>
                    <td>
                        {record.boleto_vencimento ?
                            <div>
                                {moment(record.boleto_vencimento).utc(false).format('DD/MM/YYYY')}
                                {!record.pago && <button className='btn__freedom btn--normal' style={{ marginTop: '-1px', width: '30px', height: '23px', marginLeft: '5px' }}
                                    onClick={() => {
                                        window.open(record.url, '_blank')
                                    }}
                                ><i className='fas fa-eye' /></button>}
                            </div> : //'Não gerado'
                            <button hidden={this.disable_button()} className='btn__primary--noborder' style={button_gerar_boleto} onClick={() => { this.gerarBoleto(record) }}> Gerar boleto </button>
                        }
                    </td>
                    <td>
                        {record.pago ? `Sim, dia ${moment(record.pago).utc(false).format('DD/MM/YYYY')}` : `Não`}
                        {record.baixa_manual == 1 ? " (manualmente)" : ''}
                    </td>
                    <td>
                        {transf && transf.payment_date ? `Sim, dia ${moment(transf.payment_date).utc(false).format('DD/MM/YYYY')}` : `Não`}
                        {record.repasse_garantido == 'RG' ? "(repasse garantido)" : ''}
                    </td>
                    <td>
                        <select onChange={(event) => {
                            this.setState({ acao: event.target.value })
                            if (event.target.value === 'Ajustes') this.ajustesParcela(record.id)
                            if (event.target.value === 'Retenção IR') {
                                this.setState({ servico: 6 })
                                const id = parseInt(record.id);

                                const vencimento = moment(this.state.parcelas.filter((p) => {
                                    return p.id === id
                                })[0].data_vencimento).utc(false).format('YYYY-MM-DD')

                                const mes_ano = this.state.parcelas.filter((p) => {
                                    return p.id === id
                                })[0].mes_ano

                                this.setState({
                                    vencimento: vencimento,
                                    mes_ano: mes_ano,
                                })
                                this.abrirModalIR()
                            }
                            if (event.target.value === 'Multisplit') {
                                this.setState({ servico: 49 })
                                const id = parseInt(record.id);

                                const vencimento = moment(this.state.parcelas.filter((p) => {
                                    return p.id === id
                                })[0].data_vencimento).utc(false).format('YYYY-MM-DD')

                                const mes_ano = this.state.parcelas.filter((p) => {
                                    return p.id === id
                                })[0].mes_ano

                                this.setState({
                                    vencimento: vencimento,
                                    mes_ano: mes_ano,
                                })
                                this.abrirModalMultisplit()
                            }
                            if (event.target.value === 'Demonstrativo do pagamento') {
                                var link = jwt.sign({
                                    contrato_id: this.props.match.params.id,
                                    financeiro_id: record.id,
                                    imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id
                                }, 'BOLETO')
                                window.open(`/freedom/demonstrativopagamento/${link}`)
                            }
                            if (event.target.value.split(',')[0] === 'locador') {
                                var linkDemonstrativo = jwt.sign({
                                    contrato_id: this.props.match.params.id,
                                    financeiro_id: record.id,
                                    pessoa_id: parseInt(event.target.value.split(',')[1]),
                                    imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id
                                }, 'BOLETO')
                                window.open(`/freedom/demonstrativolocador/${linkDemonstrativo}`)
                            }
                            if (event.target.value === 'Alterar parcela') this.props.history.push(`/freedom/alterarparcela/${this.props.match.params.id}/${record.id}`)
                            if (event.target.value === 'Alterar boleto') this.props.history.push(`/freedom/alterarboleto/${this.props.match.params.id}/${record.id}`)
                            if (event.target.value === 'Deletar boleto') this.modalDelete(record.boleto_id)
                            if (event.target.value === "Deletar parcela") this.confirmDelete(record.id)
                            if (event.target.value === "Baixa manual") this.openModalManual(record.boleto_id, record.document_number, record.boleto_vencimento)
                            if (event.target.value === "Reenviar boleto") this.reenviar_boleto(record.boleto_id)
                            event.target.value = 'Ações'
                        }}>
                            <option>Ações</option>
                            {!record.pago && !record.boleto_vencimento && <option>Ajustes</option>}
                            {!record.pago && !record.boleto_vencimento && <option>Retenção IR</option>}
                            <option>Demonstrativo do pagamento</option>
                            {parcelasLocador && parcelasLocador.map((pL, indice) => {
                                return <option key={indice} value={`locador,${pL.pessoa_id}`}>Demonstrativo do locador {pL.pessoa_nome.split(' ')[0]}</option>
                            })}
                            {!record.boleto_vencimento && !record.pago && <option hidden={this.disable_button()}>Alterar parcela</option>}
                            {record.boleto_vencimento && !record.pago && <option hidden={this.disable_button()}>Alterar boleto</option>}
                            {record.boleto_vencimento && !record.pago && <option hidden={this.disable_button()}>Deletar boleto</option>}
                            {!record.boleto_vencimento && !record.pago && <option hidden={this.disable_button()}>Deletar parcela</option>}
                            {record.boleto_vencimento && !record.pago && <option hidden={this.disable_button()}>Baixa manual</option>}
                            {record.boleto_vencimento && !record.pago && <option hidden={this.disable_button()}>Reenviar boleto</option>}
                            {this.state.multisplit === 1 && !record.pago && <option hidden={this.disable_button()}>Multisplit</option>}
                        </select>
                    </td>
                </tr>
            )
        }
    }

    modalDelete = (boleto_id) => {
        this.setState({
            modal_titulo: `Gerar boletos`,
            modal_corpo: <div className='text-center' style={{ padding: '2vw', fontSize: '1.1em' }}>
                Deseja realmente prosseguir? <br />O Boleto {boleto_id} será deletado.<br /><strong>Essa operação é irreversivel.</strong>
            </div>,
            modal_botoes: <>
                <button className='button btn__primary--outline' onClick={() => { this.fecharModal() }}>Não</button>
                <button className='button btn__primary' onClick={() => { this.fecharModal(); this.boletoDeletar(boleto_id); }}>Sim</button>
            </>
        })
        this.abrirModal()
    }

    boletoDeletar = async (boleto_id) => {
        await api_freedom.get(`/boleto/cancelboleto/${boleto_id}/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
        window.location.reload();
    }

    capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    abrirModal = () => {
        var modal = document.getElementById('modal');
        modal.style.display = "flex";
    }

    fecharModal = () => {
        this.setState({ modalErroGerarBoleto: '' })
        var modal = document.getElementById('modal');
        modal.style.display = "none";
    }

    confirmDelete = (id) => {
        this.setState({
            modal_titulo: 'Deseja remover esta parcela?',
            modal_botoes: <>
                <button className='button btn__primary--outline' onClick={this.fecharModal}>Não</button>
                <button className='button btn__primary' onClick={() => {
                    this.deleteParcela(id)
                }}>Sim</button>
            </>,
            modal_corpo: <div>
            </div>
        })
        this.abrirModal()
    }

    deleteParcela = async (id) => {
        await api_freedom.post("/financeiro/delete", {
            id: id
        }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

        this.contrato()
        this.parcelas()
        this.servicos()
        this.fecharModal()
    }

    ajustesParcela = (id) => {

        var vencimento = moment(this.state.parcelas.filter((p) => {
            return p.id === id
        })[0].data_vencimento).utc(false).format('YYYY-MM-DD')

        var mes_ano = this.state.parcelas.filter((p) => {
            return p.id === id
        })[0].mes_ano

        this.setState({
            vencimento_servico: vencimento,
            mes_ano_servico: mes_ano,
            modal_titulo: 'Concessão de serviços',
            modal_botoes: <>
                <button className='button btn__primary--outline' style={{ height: '30px', borderRadius: '50px' }} onClick={this.fecharModal}>Fechar</button>
                <button hidden={this.disable_button()} className='button btn__primary' style={{ height: '30px', borderRadius: '50px' }} onClick={this.salvarAcaoModal}>Salvar</button>
            </>,
            modal_corpo: <div>
                <div style={{ marginTop: '1%' }}>
                    <div className='grupo__campo'>
                        <label style={{ width: '100%' }}>Serviço: </label><br />
                        <select className='grupo__campo__form' onChange={(event) => {
                            this.setState({ servico: event.target.value })
                        }}>
                            {this.state.servicos.filter((servico) => {
                                return servico.automatico !== 1 && servico.id !== 1
                            }).map((s) => {
                                return (
                                    <option key={s.nome} value={s.id}>{s.nome}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className='grupo__campo'>
                        <label style={{ width: '100%' }}>Valor a cobrar pelo serviço: </label><br />
                        <div className='grupo__campo__form'>
                            <input type='number' defaultValue={0} min='0' onChange={(event) => {
                                this.setState({ valor_servico: event.target.value })
                            }} />
                        </div>
                    </div>
                    <div className='grupo__campo'>
                        <label style={{ width: '100%' }}>Referência do serviço: </label><br />
                        <div className='grupo__campo__form'>
                            <input type='text' defaultValue={''} onChange={(event) => {
                                this.setState({ referencia_servico: event.target.value })
                            }} placeholder='Exemplo: Serviço referente ao período 01/01/2020 a 31/01/2020' />
                        </div>
                    </div>
                    <div className='grupo__campo'>
                        <label style={{ width: '100%' }}>Data de vencimento: </label><br />
                        <div className='grupo__campo__form'>
                            <input type='date' value={vencimento} disabled readOnly />
                        </div>
                    </div>
                    <div className='grupo__campo'>
                        <label style={{ width: '100%' }}>Mês e ano de cobrança: </label><br />
                        <div className='grupo__campo__form'>
                            <input type='month' value={mes_ano} disabled readOnly />
                        </div>
                    </div>
                </div>
            </div>
        })
        this.abrirModal()

    }

    salvarAcaoModal = () => {
        if (this.state.acao === 'Ajustes') {
            this.salvarAjustes()
        }
    }

    salvarAjustes = async () => {
        if (this.state.valor_servico && parseFloat(this.state.valor_servico) !== 0) {
            let financeiro = await api_freedom.post('/financeiro/create', {
                contrato_id: this.props.match.params.id,
                imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
                servico_id: this.state.servico,
                data_vencimento: this.state.vencimento_servico,
                valor: this.state.valor_servico,
                referencia: this.state.referencia_servico,
                mes_ano: this.state.mes_ano_servico
            }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

            if (financeiro.data.data.insertId) {
                alert('Inserido com sucesso')
                this.fecharModal()
                this.contrato()
                this.parcelas()
                this.servicos()
                this.setState({ valor_servico: 0, referencia_servico: '', modal_titulo: '', modal_corpo: '', modal_botoes: '' })
            }
            else {
                alert('Ocorreu um erro, tente novamente')
            }
        }
        else {
            alert('Informe o valor cobrado para o serviço')
        }
    }

    addServiços = () => {

        const animatedComponents = makeAnimated();

        var options = this.state.parcelas.filter((p) => p.nome.toLowerCase() === 'aluguel' && p.boleto_vencimento === null).map(p => ({
            "value": p.id,
            "label": p.mes_ano,
            "venc": p.data_vencimento
        }))

        this.setState({
            modal_titulo: 'Concessão de serviços recorrentes',
            modal_botoes: <>
                <button className='button btn__primary--outline' style={{ height: '30px', borderRadius: '50px' }} onClick={this.fecharModal}>Fechar</button>
                <button hidden={this.disable_button()} className='button btn__primary' style={{ height: '30px', borderRadius: '50px' }} onClick={this.salvarConcessao}>Salvar</button>
            </>,
            modal_corpo: <div>
                <div style={{ marginTop: '1%' }}>
                    <div className='grupo__campo'>
                        <label style={{ width: '100%' }}>Serviço: </label><br />
                        <select className='grupo__campo__form' onChange={(event) => {
                            this.setState({ servico: event.target.value })
                        }}>
                            {this.state.servicos.filter((servico) => {
                                return servico.automatico !== 1 && servico.id !== 1
                            }).map((s) => {
                                return (
                                    <option key={s.nome} value={s.id}>{s.nome}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className='grupo__campo'>
                        <label style={{ width: '100%' }}>Valor a cobrar pelo serviço: </label><br />
                        <div className='grupo__campo__form'>
                            <input type='number' defaultValue={0} min='0' onChange={(event) => {
                                this.setState({ valor_servico: event.target.value })
                            }} />
                        </div>
                    </div>
                    <div className='grupo__campo'>
                        <label style={{ width: '100%' }}>Referência do serviço: </label><br />
                        <div className='grupo__campo__form'>
                            <input type='text' defaultValue={''} onChange={(event) => {
                                this.setState({ referencia_servico: event.target.value })
                            }} placeholder='Exemplo: Serviço referente ao período 01/01/2020 a 31/01/2020' />
                        </div>
                    </div>
                    <div className='grupo__campo' style={{ marginBottom: '10%' }}>
                        <label style={{ width: '100%' }}>Mês e ano de cobrança: </label><br />
                        <Select
                            placeholder={'Selecione o mês/ano'}
                            noOptionsMessage={() => 'Não há datas.'}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={options}
                            onChange={(e) => { this.setState({ value: e }) }}
                        />
                    </div>
                </div>
            </div>
        })
        this.abrirModal()

    }

    salvarConcessao = () => {
        if (this.state.valor_servico && parseFloat(this.state.valor_servico) !== 0) {

            const valueLen = this.state.value.length

            this.state.value.map(async (p, i) => {
                const financeiro = await api_freedom.post('/financeiro/create', {
                    contrato_id: this.props.match.params.id,
                    imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
                    servico_id: this.state.servico,
                    data_vencimento: moment(p.venc).utc(false).format('YYYY-MM-DD'),
                    valor: this.state.valor_servico,
                    referencia: this.state.referencia_servico,
                    mes_ano: p.label
                }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

                if (!financeiro.data.data.insertId) {
                    alert('ERRO!\nO serviço para a data', p.label, 'não fora inserido!\nTente Novamente!')
                }

                if (valueLen === i + 1) {
                    alert('SUCESSO!\nOs serviços foram adicionados.')
                    this.fecharModal()
                    this.contrato()
                    this.parcelas()
                    this.servicos()
                    this.setState({ valor_servico: 0, referencia_servico: '', modal_titulo: '', modal_corpo: '', modal_botoes: '' })
                }
            })

        }
        else {
            alert('Informe o valor cobrado para o serviço')
        }
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    gerarBoleto = async (parcela) => {
        try {
            // let financeiro = await api_freedom.post(`/boleto/gerar-unico`, {
            //     data_inicio: moment(parcela.data_vencimento).utc(false).startOf('month').format('YYYY-MM-DD'),
            //     data_fim: moment(parcela.data_vencimento).utc(false).endOf('month').format('YYYY-MM-DD'),
            //     mes_ano: parcela.mes_ano,
            //     imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
            //     contrato_id: Number(this.props.match.params.id)
            // }, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
            // let financeiro = await api_freedom.post(`/financeiro/gerarboleto/${this.props.match.params.id}/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}`, {
            //     data_inicio: moment(parcela.data_vencimento).utc(false).startOf('month').format('YYYY-MM-DD'),
            //     data_fim: moment(parcela.data_vencimento).utc(false).endOf('month').format('YYYY-MM-DD'),
            //     mes_ano: parcela.mes_ano
            // }, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
            let financeiro = await api_freedom.post(`/financeiro/gerarboleto/${this.props.match.params.id}/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}`, {
                data_inicio: moment(parcela.data_vencimento).utc(false).startOf('month').format('YYYY-MM-DD'),
                data_fim: moment(parcela.data_vencimento).utc(false).endOf('month').format('YYYY-MM-DD'),
                mes_ano: parcela.mes_ano
            }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

            if (financeiro.data.data[0].contrato_id) {
                alert('Boleto gerado')
                this.parcelas()
            }
            else {
                if (financeiro.data.data && financeiro.data.payload && financeiro.data.mensagem) {

                    this.setState({
                        modalErroGerarBoleto: <ModalErroGerarBoleto
                            dados={financeiro.data.data}
                            payload={financeiro.data.payload}
                            mensagem={financeiro.data.mensagem}
                            fechar={this.fecharModal}
                        />
                    })

                } else {

                    alert(financeiro.data.data)

                }
            }
        } catch (error) {
            console.log(error.message)
        }

    }

    alugueisAlterar = () => {

        var valor_aluguel = (this.state.parcelas.filter((p) => {
            return p.nome === 'Aluguel' && !p.boleto_id;
        })[0]?.valor) ?? 0;

        this.setState({
            valor_aluguel: valor_aluguel,
            modal_titulo: 'Aluguéis que serão alterados',
            modal_botoes: <>
                <button className='button btn__primary--outline' onClick={this.fecharModal}>Fechar</button>
                <button className='button btn__primary' onClick={() => {
                    var alugueis = []
                    var selecionados = document.querySelectorAll('input[type=checkbox]:checked')

                    for (var i = 0; i < selecionados.length; i++) {
                        alugueis.push(parseInt(selecionados[i].value))
                    }

                    this.alterarAlugueis(alugueis)
                }}>Salvar</button>
            </>,
            modal_corpo: <div>
                <div style={{ marginTop: '1%' }}>
                    <div className='grupo__campo'>
                        <label style={{ width: '100%' }}>Aluguéis: </label><br />
                        <div className='row'>
                            {this.state.parcelas.filter((p) => {
                                return p.nome === 'Aluguel' && !p.boleto_id
                            }).map((a) => {
                                return (
                                    <div key={a.id} className='col-md-4 d-flex mb-1'>

                                        <label class="mb-0 checkbox-label">
                                            <input type="checkbox" defaultChecked={false} name="check" value={a.id} />
                                            <span class="checkbox-custom rectangular"></span>
                                        </label>
                                        {moment(a.mes_ano).utc(false).format('MM/YYYY')}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='grupo__campo'>
                        <label style={{ width: '100%' }}>Valor a cobrar pelos aluguéis: </label><br />
                        <div className='grupo__campo__form'>
                            <input type='number' defaultValue={valor_aluguel} min='0' onChange={(event) => {
                                this.setState({ valor_aluguel: event.target.value })
                            }} />
                        </div>
                    </div>
                    <div className='grupo__campo'>
                        <label style={{ width: '100%' }}>Dia de Vencimento *: </label><br />
                        <div className='grupo__campo__form'>
                            <select
                                defaultValue={this.state.dataVencimento}
                                onChange={(event) => {
                                    this.setState({ dataVencimento: event.target.value })
                                }}
                                required
                            >
                                <option>Selecione</option>
                                {[...Array(31)].map((item, index) => (
                                    <option value={index + 1}>{index + 1}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        })
        this.abrirModal()

    }

    alterarAlugueis = async (alugueis) => {

        var contador = 0
        if (this.state.valor_aluguel && parseFloat(this.state.valor_aluguel) !== 0 && this.state.dataVencimento !== 'Selecione' && this.state.dataVencimento !== '') {
            alugueis.map(async (aluguel, index) => {
                let alterar = await api_freedom.post('/financeiro/updatevalordata', {
                    id: aluguel,
                    valor: this.state.valor_aluguel,
                    dia_vencimento: this.state.dataVencimento,

                }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

                if (alterar.data.data.affectedRows && alterar.data.data.affectedRows === 1) {
                    contador++
                    this.contrato()
                    this.parcelas()
                    this.servicos()
                }
                else {
                    contador++
                    alert(`Ocorreu um erro na alteração do aluguel ${aluguel}, tente novamente`)
                }

                if (contador === alugueis.length) {
                    this.fecharModal()
                }
            })
        }
        else {
            if (!this.state.valor_aluguel) {
                alert('Informe o valor a ser cobrado para os aluguéis')
            }

            if (!this.state.dataVencimento || this.state.dataVencimento === 'Selecione') {
                alert('Informe a data de vencimento')
            }
        }

    }

    saveAluguel = async () => {
        let item = this.state.parcelas.find(e => e.mes_ano == this.state.addMesAno)

        if (item) {
            alert("Este mês já foi comtemplado, escolha outro")
            return
        }

        await api_freedom.post("/financeiro/createaluguel", {
            mes_ano: this.state.addMesAno,
            contrato: this.props.match.params.id,
            imobiliaria: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
        }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

        this.contrato()
        this.parcelas()
        this.servicos()
        this.fecharModal()
    }

    saveGerarParcelas = async () => {
        this.setState({ load: true });
        const diff = Number(moment(this.state.addMesAnoFinal).diff(moment(this.state.addMesAnoInicial), 'months', true))
        let arrGerados = [];

        for (let i = 0; i <= diff; i++) {
            const mes = moment(this.state.addMesAnoInicial).add(i, 'months').format('YYYY-MM');

            const encontrou = this.state.parcelas.find(e => e.mes_ano == mes)
            if (!encontrou) {
                arrGerados.push(` ${moment(mes).format('MM/YYYY')}`)
                await api_freedom.post("/financeiro/createaluguel", {
                    mes_ano: mes,
                    contrato: this.props.match.params.id,
                    imobiliaria: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
                }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
            }
        }
        this.setState({ load: false });
        this.contrato();
        this.parcelas();
        this.servicos();
        this.fecharModal();

        if (arrGerados.length > 0) {
            alert(`
            Meses Gerados: ${arrGerados}
        `)
            return
        }
    }

    showModalAddAluguel = () => {
        this.setState({
            modal_titulo: 'Dados do alguel a ser adicionado',
            modal_botoes: <>
                <button className='button btn__primary--outline' onClick={this.fecharModal}>Fechar</button>
                <button className='button btn__primary' onClick={this.saveAluguel}>Salvar</button>
            </>,
            modal_corpo: <div>
                <div style={{ marginTop: '1%' }}>
                    <div className='grupo__campo'>
                        <label style={{ width: '100%' }}>Mês e ano de cobrança: </label><br />
                        <div className='grupo__campo__form'>
                            <input type='month' onChange={a => this.setState({ addMesAno: a.target.value })} />
                        </div>
                    </div>
                </div>
            </div>
        })
        this.abrirModal()
    }

    showModalGerarAlugueis = () => {
        this.setState({
            modal_titulo: 'Gerar Parcelas',
            modal_botoes: <>
                <button className='button btn__primary--outline' onClick={this.fecharModal}>Fechar</button>
                <button className='button btn__primary' onClick={this.saveGerarParcelas}>Salvar</button>
            </>,
            modal_corpo: <div>
                <div style={{ marginTop: '1%' }}>
                    <div className='d-flex'>
                        <div className='grupo__campo'>
                            <label style={{ width: '100%' }}>Mês e ano parcela inicial: </label><br />
                            <div className='grupo__campo__form'>
                                <input type='month' onChange={a => this.setState({ addMesAnoInicial: a.target.value })} />
                            </div>
                        </div>
                        <div className='grupo__campo' style={{ marginLeft: 10 }}>
                            <label style={{ width: '100%' }}>Mês e ano parcela final: </label><br />
                            <div className='grupo__campo__form'>
                                <input type='month' onChange={a => this.setState({ addMesAnoFinal: a.target.value })} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        })
        this.abrirModal()
    }

    handleOptionChange = (e) => {
        this.setState({
            selectedOption: e.target.value
        })
    }

    handleSelectChange = (e) => {
        this.setState({
            selectedYear: e.target.value
        })
    }

    disable_button() {
        return jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "Read"
    }

    render() {
        return (
            <>
                <div>
                    {this.state.parcelas.length > 0 &&
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <label>Total de parcelas: {this.state.total_parcelas}</label>

                                <div>

                                    <button hidden={this.disable_button()} className='btn__primary button mr-2' onClick={this.showModalGerarAlugueis}>
                                        Gerar Parcelas
                                    </button>
                                    <button hidden={this.disable_button()} className='btn__primary button mr-2' onClick={this.showModalAddAluguel}>
                                        Adicionar aluguel
                                    </button>

                                    <button hidden={this.disable_button()} className='btn__primary button'
                                        onClick={this.alugueisAlterar}>
                                        Alterar aluguéis
                                    </button>
                                    <button hidden={this.disable_button()} className='btn__primary button'
                                        onClick={this.addServiços}>
                                        Adicionar Serviços
                                    </button>
                                </div>
                            </div>

                        </>
                    }
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }} className="mr-5 align-items-center">
                            <form >
                                <label for="year">Selecione um ano:</label>
                                <select style={{ height: 20 }}
                                    value={this.state.selectedYear}
                                    onChange={this.handleSelectChange}
                                    className="grupo__campo--input--select ml-2" id="year" name="year">
                                    <option value="2025">2025</option>
                                    <option value="2024">2024</option>
                                    <option value="2023">2023</option>
                                    <option value="2022">2022</option>
                                    <option value="2021">2021</option>
                                    <option value="2020">2020</option>
                                    <option value="2019">2019</option>
                                    <option value="2018">2018</option>
                                    <option value="2017">2017</option>
                                </select>
                            </form>
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <label className="mr-4" style={{ display: 'flex', alignItems: 'center' }}>                                    <input className="mr-2"
                                    style={{ accentColor: '#430bba' }}
                                    type="radio"
                                    value="true"
                                    checked={this.state.selectedOption === 'true'}
                                    onChange={(e) => {
                                        this.handleOptionChange(e)
                                    }}
                                />
                                    Pagos
                                </label>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <label className="mr-2" style={{ accentColor: '#430bba', display: 'flex', alignItems: 'center' }}>
                                    <input
                                        className="mr-2 btn-primary"
                                        type="radio"
                                        value="false"
                                        checked={this.state.selectedOption === 'false'}
                                        onChange={(e) => {
                                            this.handleOptionChange(e)
                                        }} />
                                    Não Pagos
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='table mt-4'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>
                                        <div class="thead__item">
                                            <label>Parcela</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Mês referente</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Vencimento</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Valor do aluguel</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Detalhe boleto</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Vencimento do boleto</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Pago?</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Repassado?</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Ações</label>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.parcelas.length === 0 && <tr><th className='text-center' colSpan='8'>{this.state.mensagem}</th></tr>}
                                {this.state.parcelas.filter((p) => {
                                    return p.nome === 'Aluguel'
                                }).slice((this.state.pagina - 1) * 12, this.state.pagina * 12).map(this.listarParcelas)}
                            </tbody>
                        </table>
                    </div>
                    {/* {this.state.parcelas.length > 0 && <div className='pagination'>
                        <div className="pagination__arrow" onClick={() => {
                            this.setState({ pagina: this.state.pagina > 1 ? this.state.pagina - 1 : 1 })
                        }}>
                            {'<'}
                        </div>
                        <div className="pagination__numbers">

                            {
                                [...Array(this.state.quantidade_paginas)].map((pagina, index) => {
                                    if ((this.state.pagina === 1 && index + 1 < 4) ||
                                        (this.state.pagina === this.state.quantidade_paginas && index + 1 > this.state.quantidade_paginas - 3) ||
                                        (index + 1 === this.state.pagina - 1 || index + 1 === this.state.pagina || index + 1 === this.state.pagina + 1)
                                    ) {
                                        return (
                                            <p className={this.state.pagina === index + 1 ? 'pagination__index' : ''} onClick={() => {
                                                this.setState({ pagina: index + 1 })
                                            }}>
                                                {index + 1}
                                            </p>
                                        )
                                    }
                                    else {
                                        return null
                                    }
                                })
                            }
                        </div>
                        <div className="pagination__arrow" onClick={() => {
                            this.setState({ pagina: this.state.pagina < this.state.quantidade_paginas ? this.state.pagina + 1 : this.state.quantidade_paginas })
                        }}>
                            {'>'}
                        </div>
                    </div>} */}
                </div>
                <div className="content-modal" id="modal" style={{ display: 'none' }}>
                    <div className="modal">
                        <div className="modal-head">
                            {this.state.modal_titulo}
                        </div>
                        <div className="modal-body">
                            <div className='modal-scroll'>
                                {this.state.modal_corpo}
                            </div>
                        </div>
                        <div className="modal-foot">
                            {this.state.modal_botoes}
                        </div>
                    </div>
                </div>

                <div className="content-modal" id="modalIR" style={{ display: 'none' }}>
                    <div className="modal" style={{ width: '60%' }}>
                        <div className="modal-head">
                            Serviço Retenção de IR
                        </div>
                        <div className="modal-body">
                            <div className='modal-scroll'>
                                <div style={{ marginTop: '1%' }}>
                                    <div>
                                        <div className='grupo__campo'>
                                            <div>
                                                <label htmlFor="servicoIR">
                                                    Serviço:
                                                </label>
                                                <select
                                                    id="servicoIR"
                                                    readOnly
                                                    onChange={(event) => {
                                                        this.setState({ servico: event.target.value })
                                                    }}
                                                >
                                                    {this.state.servicos.filter((servico) => {
                                                        return servico.automatico !== 1 && servico.id === 6
                                                    }).map((s) => {
                                                        return (
                                                            <option key={s.nome} value={s.id}>{s.nome}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='grupo__campo'>
                                            <div>
                                                <label htmlFor="locadores">Locadores:</label>
                                                <select id="locadores" onChange={this.handleLocadorChange}>
                                                    {this.state.locadores.map((locador, index) => (
                                                        <option key={locador.id} value={locador.id} defaultChecked={index === 0}>
                                                            {locador.nome} ({locador.percentagem}%)
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <>
                                            <div className='grupo__campo'>
                                                <label style={{ width: '100%' }}>Valor Aluguel: </label>
                                                <br />
                                                <div className='grupo__campo__form'>
                                                    <input
                                                        readOnly
                                                        type='text'
                                                        value={this.state.valorAluguelIR}
                                                        onChange={this.handleInputChange}
                                                        placeholder='1.000,00' />
                                                </div>
                                            </div>
                                            {this.state.locadorSelecionado && (
                                                <>
                                                    <div className='grupo__campo'>
                                                        <label style={{ width: '100%' }}>Despesas dedutíveis (ao alterar os valores dedutíveis é necessário refazer o cálculo): </label>
                                                        <br />
                                                        <div className='grupo__campo__form'>
                                                            <input
                                                                type='number'
                                                                value={this.state.despesas_dedutiveis}
                                                                onChange={(e) => this.setState({ despesas_dedutiveis: e.target.value })}
                                                                placeholder='1000'
                                                            />
                                                        </div>
                                                    </div>
                                                    <button
                                                        className='button btn__primary'
                                                        onClick={() => {
                                                            this.state.locadores.forEach((locador) => {
                                                                const resultadoIR = calcularImpostoLocador(locador.percentagem, this.state.valorAluguelIR, this.state.despesas_dedutiveis, locador.nome + "; CPF: " + locador.cpf_cnpj);
                                                                locador.resultadoIR = resultadoIR.resultadoIR;
                                                                locador.aliquotaIR = resultadoIR.aliquotaIR;
                                                                locador.valorPagarIR = resultadoIR.valorPagarIR;
                                                                locador.valorAluguelIR = resultadoIR.valorAluguelIR;
                                                            });
                                                            this.forceUpdate();
                                                        }}
                                                    >
                                                        Calcular com as despesas dedutíveis
                                                    </button>
                                                </>
                                            )}
                                        </>
                                        <hr></hr>
                                    </div>
                                    {this.state.locadorSelecionado && (
                                        <div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    flewDirection: 'row',
                                                }}
                                            >
                                                <div className='grupo__campo'>
                                                    <label style={{ width: '100%' }}>Porcentagem: </label>
                                                    <br />
                                                    <div className='grupo__campo__form'>
                                                        <input
                                                            readOnly
                                                            type='text'
                                                            value={this.state.locadorSelecionado.percentagem.toFixed(2).replace('.', ',') + "%"}
                                                            onChange={this.handleInputChange}
                                                            placeholder='100%'
                                                        />
                                                    </div>
                                                </div>
                                                <div className='grupo__campo'>
                                                    <label style={{ width: '100%' }}>Alíquota: </label>
                                                    <br />
                                                    <div className='grupo__campo__form'>
                                                        <input
                                                            readOnly
                                                            type='text'
                                                            value={this.state.locadorSelecionado.aliquotaIR.toFixed(2).replace('.', ',') + "%"}
                                                            onChange={this.handleInputChange}
                                                            placeholder='100%'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='grupo__campo'>
                                                <label style={{ width: '100%' }}>Valor IR: </label>
                                                <br />
                                                <div className='grupo__campo__form'>
                                                    <input
                                                        type="number"
                                                        id="valorPagarIR"
                                                        value={this.state.locadorSelecionado.valorPagarIR}
                                                        onChange={this.handleInputChange}
                                                        placeholder='100'
                                                    />
                                                </div>
                                            </div>
                                            <div className='grupo__campo'>
                                                <label style={{ width: '100%' }}>Descrição: </label>
                                                <br />
                                                <div className='grupo__campo__form'>
                                                    <input
                                                        type="text"
                                                        id="resultadoIR"
                                                        value={this.state.locadorSelecionado.resultadoIR}
                                                        onChange={this.handleInputChange}
                                                        placeholder='IR...'
                                                    />
                                                </div>
                                            </div>
                                            <div className='grupo__campo'>
                                                <label style={{ width: '100%' }}>Data de vencimento: </label><br />
                                                <div className='grupo__campo__form'>
                                                    <input type='date' value={this.state.vencimento} readOnly />
                                                </div>
                                            </div>
                                            <div className='grupo__campo'>
                                                <label style={{ width: '100%' }}>Mês e ano de cobrança: </label><br />
                                                <div className='grupo__campo__form'>
                                                    <input type='month' value={this.state.mes_ano} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-foot">
                            <button
                                className='button btn__primary--outline'
                                onClick={this.fecharModalIR}
                            >
                                Fechar
                            </button>
                            <button
                                className='button btn__primary'
                                hidden={this.disable_button()}
                                onClick={async () => {
                                    let financeiro;
                                    this.setState({ load: true });
                                    for (const locador of this.state.locadores) {
                                        console.log(locador)
                                        if (parseFloat(String(locador.valorPagarIR).replace(',', '.')) > 0) {
                                            financeiro = await api_freedom.post('/financeiro/create', {
                                                contrato_id: this.props.match.params.id,
                                                imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
                                                servico_id: this.state.servico,
                                                data_vencimento: this.state.vencimento,
                                                valor: parseFloat(String(locador.valorPagarIR).replace(',', '.')),
                                                mes_ano: this.state.mes_ano,
                                                referencia: locador.resultadoIR,
                                                pessoa_id: locador.id,
                                            }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
                                        }
                                    }
                                    this.setState({ load: false });
                                    if (!financeiro) {
                                        alert('Ocorreu um erro')
                                        this.fecharModal()
                                        this.fecharModalIR()
                                        this.contrato()
                                        this.parcelas()
                                        this.servicos()
                                        this.setState({ valor_servico: 0, referencia_servico: '', modal_titulo: '', modal_corpo: '', modal_botoes: '' })
                                        return
                                    }
                                    if (financeiro.data.data) {
                                        alert('Inserido com sucesso')
                                        this.fecharModal()
                                        this.fecharModalIR()
                                        this.contrato()
                                        this.parcelas()
                                        this.servicos()
                                        this.setState({ valor_servico: 0, referencia_servico: '', modal_titulo: '', modal_corpo: '', modal_botoes: '' })
                                    }
                                }}
                            >
                                Salvar Cálculo
                            </button>

                        </div>
                    </div>
                </div>
                <div className="content-modal" id="multisplit" style={{ display: 'none' }}>
                    <div className="modal" style={{ width: '60%' }}>
                        <div className="modal-head">
                            Muiltisplit
                        </div>
                        <div className="modal-body">
                            <div className='modal-scroll'>
                                <div style={{ marginTop: '1%' }}>
                                    <div>
                                        <>
                                            <div className='grupo__campo'>
                                                <label style={{ width: '100%' }}>Descrição do Pagamento</label>
                                                <br />
                                                <div className='grupo__campo__form'>
                                                    <input
                                                        type='text'
                                                        value={this.state.descricao_pagamento}
                                                        onChange={(event) => {
                                                            this.setState({ descricao_pagamento: event.target.value })
                                                        }}
                                                        placeholder='Comissão, pagamento de manutenções e etc' />
                                                </div>
                                            </div>
                                            <div className='grupo__campo'>
                                                <label style={{ width: '100%' }}>Valor: </label>
                                                <br />
                                                <div className='grupo__campo__form'>
                                                    <input
                                                        type='text'
                                                        value={this.state.valor_multisplit}
                                                        onChange={(event) => {
                                                            this.setState({ valor_multisplit: event.target.value })
                                                        }}
                                                        placeholder='1.000,00' />
                                                </div>
                                            </div>
                                            <label style={{ marginTop: '30px', fontSize: '1.1em', fontWeight: 'bolder' }}>Dados bancários</label>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <div className='grupo__campo' name={`banco`}>
                                                        <label style={{ width: '100%' }}>Banco: *</label><br />
                                                        <div className='grupo__campo__form'>
                                                            <input type='number' value={this.state.banco}
                                                                onChange={(event) => {
                                                                    this.setState({ banco: event.target.value })
                                                                }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='grupo__campo' name={`agencia`}>
                                                        <label style={{ width: '100%' }}>Agência: *</label><br />
                                                        <div className='grupo__campo__form'>
                                                            <input type='text' value={this.state.agencia} onChange={(event) => {
                                                                this.setState({ agencia: event.target.value })
                                                            }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='grupo__campo' name={`conta`}>
                                                        <label style={{ width: '100%' }}>Conta: *</label><br />
                                                        <div className='grupo__campo__form'>
                                                            <input type='text' value={this.state.conta} onChange={(event) => {
                                                                this.setState({ conta: event.target.value })
                                                            }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='grupo__campo' name={`corrente_poupanca`}>
                                                        <label style={{ width: '100%' }}>Corrente ou poupança: *</label><br />
                                                        <div className='grupo__campo__form'>
                                                            <select value={this.state.corrente_poupanca} onChange={(event) => {
                                                                this.setState({ corrente_poupanca: event.target.value })
                                                            }}>
                                                                <option value={0}>Corrente</option>
                                                                <option value={1}>Poupança</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='grupo__campo' name={`beneficiario_nome`}>
                                                        <label style={{ width: '100%' }}>Beneficiário Nome: *</label><br />
                                                        <div className='grupo__campo__form'>
                                                            <input type='text' value={this.state.beneficiario_nome} onChange={(event) => {
                                                                this.setState({ beneficiario_nome: event.target.value })
                                                            }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='grupo__campo' name={`beneficiario_cpf_cnpj`}>
                                                        <label style={{ width: '100%' }}>Benediciário CPF/CNPJ: *</label><br />
                                                        <div className='grupo__campo__form'>
                                                            <input type='text' value={this.state.beneficiario_cpf_cnpj} onChange={(event) => {
                                                                this.setState({ beneficiario_cpf_cnpj: event.target.value })
                                                            }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-foot">
                            <button
                                className='button btn__primary--outline'
                                onClick={this.fecharModalMultisplit}
                            >
                                Fechar
                            </button>
                            <button
                                className='button btn__primary'
                                hidden={this.disable_button()}
                                onClick={async () => {
                                    let financeiro;
                                    this.setState({ load: true });
                                    if (parseFloat(String(this.state.valor_multisplit).replace(',', '.')) > 0) {
                                        financeiro = await api_freedom.post('/financeiro/create', {
                                            contrato_id: this.props.match.params.id,
                                            imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
                                            servico_id: this.state.servico,
                                            data_vencimento: this.state.vencimento,
                                            valor: parseFloat(String(this.state.valor_multisplit).replace(',', '.')),
                                            mes_ano: this.state.mes_ano,
                                            referencia: 'Multisplit ' + `(${(this.state.descricao_pagamento)} / Valor: R$ ${this.state.valor_multisplit}) / Para: ${this.state.beneficiario_nome}`,
                                        }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
                                    }
                                    this.setState({ load: false });
                                    if (!financeiro) {
                                        alert('Ocorreu um erro')
                                        this.fecharModal()
                                        this.fecharModalMultisplit()
                                        this.contrato()
                                        this.parcelas()
                                        this.servicos()
                                        this.setState({ descricao_pagamento: '', valor_multisplit: '', beneficiario_cpf_cnpj: '', beneficiario_nome: '', corrente_poupanca: 0, banco: '', agencia: '' })
                                        return
                                    }
                                    if (financeiro.data.data) {
                                        if (financeiro.data.criado) {
                                            await api_freedom.post('/multisplit/create', {
                                                descricao_pagamento: this.state.descricao_pagamento,
                                                banco: this.state.banco,
                                                agencia: this.state.agencia,
                                                conta: this.state.conta,
                                                tipo_conta: this.state.corrente_poupanca,
                                                beneficiario_nome: this.state.beneficiario_nome,
                                                beneficiario_cpf: this.state.beneficiario_cpf_cnpj,
                                                financeiro_id: financeiro.data.criado.id,
                                                valor: parseFloat(String(this.state.valor_multisplit).replace(',', '.')),
                                            }, {
                                                headers: {
                                                    "authorization": localStorage.getItem('autenticado_freedom')
                                                }
                                            });
                                        }
                                        alert('Inserido com sucesso')
                                        this.fecharModal()
                                        this.fecharModalMultisplit()
                                        this.contrato()
                                        this.parcelas()
                                        this.servicos()
                                        this.setState({ descricao_pagamento: '', valor_multisplit: '', beneficiario_cpf_cnpj: '', beneficiario_nome: '', corrente_poupanca: 0, banco: '', agencia: '', conta: '' })
                                    }
                                }}
                            >
                                Salvar
                            </button>

                        </div>
                    </div>
                </div>

                <div
                    className="content-modal"
                    style={{ display: this.state.load ? "flex" : "none" }}
                >
                    <div className="modal" style={{ width: "auto" }}>
                        <img
                            src={require("../assets/imgs/loading.gif")}
                            alt=""
                            style={{ height: "200px" }}
                        />
                    </div>
                </div>
                {this.state.modalErroGerarBoleto && this.state.modalErroGerarBoleto}
            </>
        );
    }
}

export default Parcelas;
